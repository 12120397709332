import React, { ReactNode } from 'react'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'

type MaxWidthContainerProps = {
    children: ReactNode
    className?: string
}

const MaxWidthContainer = ({ children, className }: MaxWidthContainerProps) => (
    <div
        css={(th: Theme) => css`
            padding: 0 ${th.spacing.sm};
            margin: 0 auto;
            max-width: ${th.container.width};
        `}
        className={className}
    >
        {children}
    </div>
)

export default MaxWidthContainer
